import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';

class BpCalculatorField {
  title: string;
  value: Observable<any>;
  options: ObservableArray<any>;
  filterText: Observable<string>;
  action?: () => void;
  optionsCaption: Observable<string>;
  originalOptions: any[];
  originalCaption: string;
  errors: Observable<string>;

  constructor(params: any) {
    this.title = params.title;
    this.value = params.value;
    this.options = ko.observableArray(params.options);
    this.filterText = ko.isObservable(params.filterText) ? params.filterText : ko.observable('');
    this.optionsCaption = ko.isObservable(params.optionsCaption) ? params.optionsCaption : ko.observable(null);
    this.originalCaption = ko.isObservable(params.optionsCaption) ? params.optionsCaption() : null;
    this.errors = ko.observable('');

    this.originalOptions = this.options();

    this.action = () => {
      //Perform calculation based on equationText
      var answer = 0;
      this.errors('');

      if (this.filterText().indexOf('+') > -1) {
        var values = this.filterText().split('+');

        values.forEach(v => {
          if (isNaN(Number(v))) {
            this.errors(`${v} is not a number`);
          } else {
            answer += Number(v);
          }
        });
      } else if (this.filterText().indexOf('-') > -1) {
        var values = this.filterText().split('-');

        for (var i = 0; i < values.length; i++) {
          var value = Number(values[i]);
          if (isNaN(value)) {
            return;
          }
          answer = answer == 0 ? value : answer - value;
        }
      } else if (this.filterText().indexOf('*') > -1) {
        var values = this.filterText().split('*');

        for (var i = 0; i < values.length; i++) {
          var value = Number(values[i]);
          if (isNaN(value)) {
            return;
          }
          answer = answer == 0 ? value : answer * value;
        }
      } else if (this.filterText().indexOf('/') > -1) {
        var values = this.filterText().split('/');

        for (var i = 0; i < values.length; i++) {
          var value = Number(values[i]);
          if (isNaN(value)) {
            return;
          }
          answer = answer == 0 ? value : answer / value;
        }
      } else {
        this.errors(`Your equation is not parsable.`);
      }

      if (this.errors().length > 0) {
        this.value(this.errors());

        return;
      }

      this.value((answer * 100).toFixed(2));
      this.filterText('');
    }
  }
}

export default {
  name: 'bp-calculator-field',
  viewModel: BpCalculatorField,
  template: require('./bp-calculator-field.html')
};