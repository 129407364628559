import * as ko from 'knockout';
import bpFooter from './bp-footer';
import wpHeader from './wp-header';
import bpSidebar from './bp-sidebar';

export default function () {
  ko.components.register(bpFooter.name, bpFooter);
  ko.components.register(wpHeader.name, wpHeader);
  ko.components.register(bpSidebar.name, bpSidebar);
}
