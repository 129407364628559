import * as ko from 'knockout';
import { ObservableArray } from 'knockout';
import { userService } from '../../../api/service.auth';
import router from '../../../routing/router';
import routes from '../../../routing/routes';

class ValidateEmail {
  messages: ObservableArray<{ type: string, message: string}>;

  constructor(params: any) {
    this.messages = ko.observableArray();

    this.messages.push({ type: 'message', message: 'Validating email with token...' });
    this.messages.push({ type: 'token', message: params.token });

    if (!params.token) {
      this.messages([]);
      this.messages.push({ type: 'error', message: 'Email validation failed, token is required.' });

      return;
    }

    userService.verifyEmail(params.token, params.email)
      .then(() => {
        this.messages.push({ type: 'message', message: 'Email validated.' });
      })
      .catch(() => {
        this.messages.push({ type: 'error', message: 'Email validation failed, invalid token.' });
      });
  }
  goto = {
    login: (): void => router.goto(routes.login.interpolate({}))
  }
}

export default {
  name: 'validate-email',
  viewModel: ValidateEmail,
  template: require('./validate-email.html')
};
