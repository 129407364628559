import router from '../../../routing/router';
import routes from '../../../routing/routes';

class ValidateEmailInstructions {
  goto = {
    login: (): void => router.goto(routes.login.interpolate({}))
  }
}

export default {
  name: 'validate-email-instructions',
  viewModel: ValidateEmailInstructions,
  template: require('./validate-email-instructions.html')
};
