import { userService } from './service.auth';

export function handleResponse(response: any): Promise<any> {
  return response.text().then((text: any) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // attempt to get new token using the refresh token

        userService.logout();

        location.reload(true);

        //userService.refreshToken()
        //  .then(user => {
        //    if (!user.token) {
        //      userService.logout();
        //    } else {
        //      localStorage.setItem('user', JSON.stringify(user));
        //    }

        //    location.reload(true);
        //  })
        //  .catch(() => {
        //    userService.logout();

        //    location.reload(true);
        //  });
      }

      const error = data || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
