import * as ko from 'knockout';
import forgotPassword from './forgot-password';
import login from './login';
import register from './register';
import resetPassword from './reset-password';
import resetPasswordInstructions from './reset-password-instructions';
import validateEmail from './validate-email';
import validateEmailInstructions from './validate-email-instructions';

export default function () {
  ko.components.register(login.name, login);
  ko.components.register(register.name, register);
  ko.components.register(forgotPassword.name, forgotPassword);
  ko.components.register(validateEmail.name, validateEmail);
  ko.components.register(validateEmailInstructions.name, validateEmailInstructions);
  ko.components.register(resetPassword.name, resetPassword);
  ko.components.register(resetPasswordInstructions.name, resetPasswordInstructions);
}
