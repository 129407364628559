export function authHeader() {
  // return authorization header with token
  const userJson = localStorage.getItem('user');
  if (!userJson) {
    return { 'AuthToken': '' };
  }

  const user = JSON.parse(userJson);

  if (user && user.token) {
    return { 'AuthToken': `${user.token}` };
  } else {
    return { 'AuthToken': '' };
  }
}
