import { ObservableArray } from 'knockout';

class BpSidebar {
  links: ObservableArray<any>;

  constructor(params: any) {
    this.links = params.links;
  }
}

export default {
  name: 'bp-sidebar',
  viewModel: BpSidebar,
  template: require('./bp-sidebar.html')
};
