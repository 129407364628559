class BpFooter {
  constructor(params: any) {
  }
}

export default {
  name: 'bp-footer',
  viewModel: BpFooter,
  template: require('./bp-footer.html')
};
