import { authHeader } from './auth-header';

export function authPostDataRequest(data: any) {
  return ({
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json'
    },
    credentials: 'include' as RequestCredentials,
    body: JSON.stringify(data)
  });
};
