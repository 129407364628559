import * as ko from 'knockout';
import { Computed } from 'knockout';

class BpImg {
  iconHtml: Computed<string | null>;

  constructor(params: any) {
    this.iconHtml = ko.pureComputed(() =>
      this.icon(ko.unwrap(params.iconName))
    );
  }

  icon = (name: string): string | null => {
    if (!name) {
      return null;
    }
    return require(`../../svg/${name}.svg`);
  }
}

export default {
  name: 'bp-img',
  viewModel: BpImg,
  template: require('./bp-img.html')
};
