import { formatDateTime } from '../../utils/format';

class BpSidebarHistory {
  //icon: Computed<string>;
  date: string;
  label: string;
  description: string;


  constructor(params: any) {
    //this.icon = ko.pureComputed(() => params.icon ? ko.unwrap(params.icon) : '');

    this.date = params.date;
    this.label = params.label;
    this.description = params.description;

  }
}

export default {
  name: 'bp-sidebar-history',
  viewModel: BpSidebarHistory,
  template: require('./bp-sidebar-history.html')
};
