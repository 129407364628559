import * as crossroads from 'crossroads';
import app from '../components/app';

class Router {
  constructor() {
    window.onpopstate = () => {
      if (app.checkBeforeNavigation()) {
        if (!confirm('You are about to navigate away from this page without saving the data.  Are you sure you want to do that?')) {
          return;
        }
        app.checkBeforeNavigation(false);
      }

      crossroads.resetState();

      crossroads.parse(window.location.pathname + window.location.search);
    };
  }

  goto(path: string, pushState: boolean = true): any {
    if (app.checkBeforeNavigation()) {
      if (!confirm('You are about to navigate away from this page without saving the data.  Are you sure you want to do that?')) {
        return;
      }
      app.checkBeforeNavigation(false);
    }

    crossroads.parse(path);

    if (pushState) {
      history.pushState({}, '', `${path}`);
    }
  }
}

const router = new Router();

export default router;
