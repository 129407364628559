import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';

class BpSearchableDropdown {
  title: string;
  value: Observable<any>;
  setFocus?: boolean | undefined;
  min?: Observable<any>;
  max?: Observable<any>;
  step?: number;
  options: ObservableArray<any>;
  optionsText?: (o: any) => string;
  optionsValue?: (o: any) => string;
  filterText: Observable<string>;
  action?: () => void;
  optionsCaption: Observable<string>;
  originalOptions: any[];
  originalCaption: string;

  constructor(params: any) {
    this.title = params.title;
    this.value = params.value;
    this.options = params.options || ko.observableArray();
    this.optionsText = params.optionsText;
    this.optionsValue = params.optionsValue;
    this.filterText = ko.isObservable(params.filterText) ? params.filterText : ko.observable('');
    this.optionsCaption = ko.isObservable(params.optionsCaption) ? params.optionsCaption : ko.observable(null);
    this.originalCaption = ko.isObservable(params.optionsCaption) ? params.optionsCaption() : null;

    this.originalOptions = this.options();


    this.filterText.subscribe(() => {
      const filterText = this.filterText;

      if (filterText == null) { return; }

      this.filterOptions(filterText())
    });
  }

  filterOptions = (filterText: string) => {
    let options = [];
    if (filterText === '') {
      options = this.originalOptions;
    }
    else {
      options = this.originalOptions.filter((o) => o.Name.toLowerCase().indexOf(filterText.toLowerCase()) > -1);
    }

    this.options(options);

    if (options.length === 1) {
      this.value(options[0].Id);
    }

    if (this.options().length == 0) {
      this.optionsCaption('No items');
    } else {
      this.optionsCaption(this.originalCaption);
    }
  };
}

export default {
  name: 'bp-searchable-dropdown',
  viewModel: BpSearchableDropdown,
  template: require('./bp-searchable-dropdown.html')
};