import router from '../../../routing/router';
import routes from '../../../routing/routes';

class ValidateEmailInstructions {
  goto = {
    home: (): void => router.goto(routes.home.interpolate({}))
  }
}

export default {
  name: 'reset-password-instructions',
  viewModel: ValidateEmailInstructions,
  template: require('./reset-password-instructions.html')
};
