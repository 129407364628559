import { Observable, Computed } from 'knockout';
import * as ko from 'knockout';
import router from '../../routing/router';

class BpSidebarLink {
  action: () => void;
  url: string;
  icon: Computed<string>;
  label: string;
  description: string;
  enable: Observable<boolean>;

  constructor(params: any) {
    this.action = params.action || (() => router.goto(params.url));

    this.url = params.url;

    this.icon = ko.pureComputed(() => params.icon ? ko.unwrap(params.icon) : '');

    this.label = params.label;

    this.description = params.description;

    this.enable = ko.isObservable(params.enable)
      ? params.enable : ko.observable(true);
  }

  isActive = (): boolean => {
    return window.location.pathname === this.url;
  }
}

export default {
  name: 'bp-sidebar-link',
  viewModel: BpSidebarLink,
  template: require('./bp-sidebar-link.html')
};
