import * as ko from 'knockout';
import { Observable } from 'knockout';

class BpTimeDate {
  label: Observable<string>;
  local: Observable<any>;

  constructor(params: any) {
    this.label = ko.observable(params.label);
    this.local = params.local;
  }
}

export default {
  name: 'bp-time-date',
  viewModel: BpTimeDate,
  template: require('./bp-time-date.html')
};
