import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';

export type QuickDialogParams = {
  title: Observable<string> | string;
  message: Observable<string> | string;
  fields?: ObservableArray<any>;
  value: Observable<number | string | null>;
};

export type FieldType<T> = {
  title: Observable<string> | string;
  type: 'text' | 'dropdown' | 'searchable-dropdown' | 'checkbox' | 'number' | 'password' | 'date' | string;
  value: Observable<T | number | string | boolean | undefined | null>;
  visible?: Observable<boolean> | undefined;
  setFocus?: boolean | undefined;
  min?: Observable<any> | undefined;
  max?: Observable<any> | undefined;
  step?: number | undefined;
  options?: Observable<T[]> | T[],
  optionsText?: (o: T | undefined) => string | undefined,
  optionsValue?: (o: T | undefined) => number | string | undefined,
  filterText?: Observable<string> | undefined;
  action?: () => void;
  optionsCaption?: Observable<string> | undefined | null;
  iconName?: string | null;
}

class BpQuickDialog {
  readonly title: Observable<string>;
  readonly message: Observable<string>;
  readonly fields: ObservableArray<any>;
  readonly value: Observable<number | string | null>;

  constructor(params: QuickDialogParams) {
    this.title = ko.isObservable(params.title) ? params.title : ko.observable(params.title);
    this.message = ko.isObservable(params.message) ? params.message : ko.observable(params.message);
    this.fields = params.fields || ko.observableArray();
    this.value = params.value;
  }
}

export default {
  name: 'bp-quick-dialog',
  viewModel: BpQuickDialog,
  template: require('./bp-quick-dialog.html')
};
