import { Observable, Computed } from 'knockout';
import * as ko from 'knockout';
import { cart } from '../app';
import { CartItem } from '../../utils/shoppingCart';

class WpAddToCart {
  productId: number;
  name: string;
  unitPrice: number;
  unitWeight: number;
  originalQuantity: Observable<number>;
  quantity: Observable<string>;
  isFocussed: Observable<boolean>;
  updateCart: (productId: number, quantity: number) => number;
  incrementCart: (productId: number) => number;
  decrementCart: (productId: number) => number;

  updateVisible: Observable<boolean>;

  constructor(params: any) {

    this.productId = params.productId;
    this.name = params.name;
    this.unitPrice = params.unitPrice;
    this.unitWeight = params.unitWeight;

    this.updateCart = params.updateCart;
    this.incrementCart = params.incrementCart;
    this.decrementCart = params.decrementCart;

    this.updateVisible = ko.observable(false);

    this.originalQuantity = ko.observable(0);
    this.quantity = ko.observable('0');    //text input

    this.originalQuantity.subscribe(() => {
      this.quantity(`${this.originalQuantity()}`);
      this.updateVisible(this.originalQuantity() > 0);
    });

    // If product is in cart, update quantity
    const cartProducts: CartItem[] = cart.contents().filter(c => c.productId === this.productId);

    if (cartProducts.length > 0 && cartProducts[0]) {
      this.originalQuantity(cartProducts[0].quantity);
      this.quantity(`${this.originalQuantity()}`);

    }

    this.isFocussed = ko.observable(false);
  }

  focusOn = () => {
    this.isFocussed(true);
  }

  focusOff = () => {
    this.isFocussed(false);
  }

  actions = {
    updateCart: () => {
      const qty = Number(this.quantity());

      if (qty == NaN) {
        this.quantity(`${this.originalQuantity()}`);
        return;
      }

      this.originalQuantity(this.updateCart(this.productId, qty));
      this.originalQuantity.notifySubscribers();
    },

    incrementCart: () => this.originalQuantity(this.incrementCart(this.productId)),
    decrementCart: () => this.originalQuantity(this.decrementCart(this.productId)),
    keydown: (e: KeyboardEvent): boolean => {
      switch (e.keyCode) {
        case 13:
          {
            const qty = Number(this.quantity());

            if (qty == NaN) {
              this.quantity(`${this.originalQuantity()}`);
              break;
            }

            this.originalQuantity(this.updateCart(this.productId, qty));
            this.originalQuantity.notifySubscribers();
          }
          break;

        case 27:
          {
            this.quantity(`${this.originalQuantity()}`);
          }
          break;
      }

      return true;
    }
  }
}

export default {
  name: 'wp-add-to-cart',
  viewModel: WpAddToCart,
  template: require('./wp-add-to-cart.html')
};
