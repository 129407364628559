import * as ko from 'knockout';
import app from './app';
import registerElements from './elements/_index';
import registerNav from './nav/_index';
import registerSections from './sections/_index';
import registerViews from './views/_index';

export default function () {
  ko.components.register(app.name, app);

  registerElements();
  registerNav();
  registerSections();
  registerViews();
}
